import "./ActionBar.scss";

import FullscreenButton from "../Buttons/Fullscreen/FullscreenButton";
import InformationButton from "../Buttons/Information/InformationButton";
import SupportInformationButton from "../Buttons/SupportInformation/SupportInformationButton";
import ContactButton from "../Buttons/Contact/ContactButton";
import ShareButton from "../Buttons/Share/ShareButton";
import RegionButton from "../Buttons/Region/RegionButton";
import ActionButtonContainer from "../ActionButtonContainer/ActionButtonContainer";
import DimensionButton from "../Buttons/dimension/dimensionButton";

const ActionBar = (props) => {
   if (!props.isVisible){
      return null
   }
   return (
      <div className="action-bar">
         <div className="action-bar__left">
            <InformationButton />
            <ContactButton />
            <SupportInformationButton />
         </div>

         <ActionButtonContainer deviceType={"desktop"}/>
         <div className="action-bar__right">
            <DimensionButton/>
            {<RegionButton />}
            <ShareButton />
            <FullscreenButton />
         </div>
      </div>
   );
};

export default ActionBar;

import "./DisclaimerModal.scss";
import Modal from "../../Modals/Modal/Modal";
import CloseButton from "../../Buttons/Close/CloseButton";
import { useConfig } from "../../../contexts/useConfig";
import { useEffect, useState } from "react";
import {getPropertyOrDefault} from "../../../helpers/helpers";

export default function SupportInformationModal(props) {
   const [config] = useConfig();

   const disclaimerContent = getPropertyOrDefault(config.disclaimerInformation.disclaimerInfoText, "");
   const [disclaimerTextContent, setDisclaimerTextContent] = useState(typeof(disclaimerContent) === "string"  ? (disclaimerContent).split('\n') : disclaimerContent );

   useEffect (()=>{
      if (typeof(disclaimerContent) === "string"){
         setDisclaimerTextContent((disclaimerContent).split('\n'))
      }
   }, [disclaimerContent])

   return (
      <Modal canShow={props.canShow} className="disclaimer-modal" onClickBackground={() => props.updateModalState(false)}>
         <CloseButton closeModal={() => props.updateModalState(false)} />
         {(config.disclaimerInformation.disclaimerInfoHeading && 
            <h2 className="disclaimer-modal__heading">{(config.disclaimerInformation.disclaimerInfoHeading)}</h2>
         )}
         <div className="disclaimer-inner-container">
            <p className="disclaimer-inner-container__text">{disclaimerTextContent.map((line, index) => <span key={index}>{line}<br/></span>)}</p>
         </div>
      </Modal>
   );
}

import ActionBar from "../ActionBar/ActionBar";
import ActionButtonContainer from "../ActionButtonContainer/ActionButtonContainer";
import CustomLogosContainer from "../CustomLogosContainer/CustomLogosContainer";
import ModelSelector from "../ModelSelector/ModelSelector";
import ModelTitle from "../ModelTitle/ModelTitle";
import Watermark from "../Watermark/Watermark";
import "./ViewerUI.scss";
import { useViewerMode } from "../../contexts/useViewerMode";
import { motion } from "framer-motion";
import { useState, useEffect } from "react"


const variants = {
   fadeBackground: { backgroundColor: `rgb(85, 87, 96, 0.5)` },
   clearBackground: { backgroundColor: `rgba(0, 0, 0, ${0.0})` },
};

const ViewerUI = ({uiVisible}) => {
   const [viewerMode] = useViewerMode();
   const [fadeBackground, setFadeBackground] = useState();

   const fadeBackgroundOpacity = 0.5;

   useEffect(() => {
      setFadeBackground(viewerMode === "select")
   }, [viewerMode])

   return (
      <motion.div
         className="ui"
         variants={variants}
         animate={fadeBackground ? "fadeBackground" : "clearBackground"}
         transition={{ duration: 0.5 }}
      >
         <div className="ui-header">
            <ActionBar isVisible={uiVisible}/>
         </div>
         <div className="ui-main">
            <ModelSelector />
         </div>
         <div className="ui-footer">
            <div className="ui-footer-row">
               <ModelTitle />
               <Watermark />
               <CustomLogosContainer fadeBackground={fadeBackground} fadeBackgroundOpacity={fadeBackgroundOpacity}/>
            </div>
            <ActionButtonContainer deviceType={"mobile"} isVisible={uiVisible} />
         </div>
      </motion.div>
   );
};

export default ViewerUI;

import "./Hotspot.scss";
import { useState, useEffect, useRef } from "react"
import { motion } from "framer-motion"
import ContentIcon from "../ContentIcon/ContentIcon";
import HotspotLabel from "./HotspotLabel/HotspotLabel";
import {getPropertyOrDefault} from "../../helpers/helpers";
import isMobile from "is-mobile";
import { useVideoModal } from "../../contexts/useVideoModal";
import { useHotspotModal } from "../../contexts/useHotspotModal";

export default function Hotspot({ hotspotData, setCurrentHotspot, currentHotspot, 
      handleAnimationDataChanged, hotspotOptions, hotspotsVisible, arStatus}) {
   const position = `${hotspotData.position.x} ${hotspotData.position.y} ${hotspotData.position.z}`;
   const normal = `${hotspotData.normal.x} ${hotspotData.normal.y} ${hotspotData.normal.z}`;
   const hotspotBackfacingDisplayMode = getPropertyOrDefault(hotspotOptions?.defaultContentDisplayModeDesktop, "invisible");
   const defaultContentDisplayModeDesktop = getPropertyOrDefault(hotspotOptions?.hotspotBackfacingDisplayMode, "worldSpace");
   const defaultContentDisplayModeMobile = getPropertyOrDefault(hotspotOptions?.defaultContentDisplayModeMobile, "worldSpace");
   const contentDisplayModeDesktop = getPropertyOrDefault(hotspotData.contentDisplayModeDesktop, defaultContentDisplayModeDesktop)
   const contentDisplayModeMobile = getPropertyOrDefault(hotspotData.contentDisplayModeMobile, defaultContentDisplayModeMobile)

   const [contentDisplayMode, setContentDisplayMode] = useState();

   const [clickable, setClickable] = useState(false);
   //const [captionHideTime, setCaptionHideTime] = useState(0);
   const [active, setActive] = useState();
   const [isVisible, setIsVisible] = useState();

   const {
      setVideoModalVisible,
      setVideoModalUrl,
      setVideoModalTitle,
      setVideoTextContent,
      setVideoModalSource,
      setVideoAutoplay,
      setCloseOnVideoEnded
   } = useVideoModal();

   const {
       setHotspotModalVisible,
       setHotspotData,
       setHotspotOptions,
   } = useHotspotModal();

   const hotspotRef = useRef(null);
   
   useEffect(() => {
      hotspotRef.current.addEventListener(
         "hotspot-visibility",
         (e) => {
            setIsVisible(e.detail.visible);
            setClickable(e.detail.visible);
         },
         false
      );
   });



   useEffect(() => {
      //const checkIsActive = () => {
         //if (hotspotData.contentType.toLowerCase() === "animation") {
            //setActive(Date.now() < captionHideTime);
         //} else {
            //setActive(hotspotData.id === currentHotspot);
      //}
      //var interval = setInterval(checkIsActive, 100)
      //return () => clearInterval(interval)

      setActive(hotspotData.id === currentHotspot);
   } , [setActive, hotspotData, currentHotspot])

   //const showCaption = () => {
      //setCaptionHideTime(Date.now() + (1000 * hotspotData.captionDuration));
   //}


   useEffect(() => {
      console.log("arStatus", arStatus)
      if(arStatus === "session-started" || arStatus === "object-placed") {
         setContentDisplayMode("worldSpace");
      } else {
         const displayMode = isMobile() ? contentDisplayModeMobile : contentDisplayModeDesktop;
         setContentDisplayMode(displayMode);
      }
   }, [arStatus, contentDisplayMode, setContentDisplayMode, contentDisplayModeDesktop, contentDisplayModeMobile])
   

   const onHotspotClicked = () => {
      if (hotspotData.contentType.toLowerCase() === "animation") {
         if (!active) {
            const name = hotspotData.animationName;
            const speed = hotspotData.animationSpeed;
            const options = {
               repetitions: hotspotData.animationRepetitions,
               pingpong: hotspotData.animationMode.toLowerCase() === "pingpong",
               isolate: hotspotData.isolate ? hotspotData.isolate : false,
               delay: hotspotData.delay ? hotspotData.delay : 0,
            };
            handleAnimationDataChanged({ name, speed, options });
            if (hotspotData.captionDuration) {
               setTimeout(() => {
                  setActive(false);
                  setCurrentHotspot(null);
               }, hotspotData.captionDuration);
            }
         }
      }
      
      if (
         hotspotData.contentType.toLowerCase() !== "animation" &&
         contentDisplayMode.toLowerCase() === "screenspace"
      ) {
         setCurrentHotspot(null);
         if (hotspotData.contentType.toLowerCase() === "video") {
            setVideoModalVisible(true);
            setVideoModalUrl(hotspotData.videoUrl);
            setVideoModalSource(hotspotData.videoSource);
            setVideoModalTitle(hotspotData.title);
            setVideoTextContent(hotspotData.content);
            setVideoAutoplay(hotspotData.videoAutoplay);
            setCloseOnVideoEnded(hotspotData.closeOnVideoEnded)
         } else {
            setCurrentHotspot(hotspotData.id);
            setHotspotModalVisible(true);
            setHotspotData(hotspotData);
            setHotspotOptions(hotspotOptions);
         }
      } else {
         if (hotspotData.id === currentHotspot) {
            setCurrentHotspot(null);
         } else {
            setCurrentHotspot(hotspotData.id);
         }
      }
   };


   const hotspotVariants = {
      open: { opacity: 1 },
      closed: { opacity: 0, transition: { delay: 0.3 } },
   };
   if (hotspotsVisible || active) {
      return (
         <motion.div ref={hotspotRef}
            className={`hotspot-container ${isVisible ? "" : hotspotBackfacingDisplayMode}`}
            data-normal={normal}
            data-position={position}
            slot={`hotspot-${hotspotData.id}`}
            data-visibility-attribute="visible"
            initial={"closed"}
            animate={clickable ? "open" : "closed"}
            variants={hotspotVariants}
         >
               <motion.button
                  className="hotspot pulse"
                  onClick={clickable ? onHotspotClicked : null}
                  whileHover={clickable && {
                     scale: 1.2,
                     transition: { duration: 0.3 },
                  }}
                  whileTap={clickable && { scale: 0.9 }}
               >
                  <ContentIcon
                     fontAwesomeIconName={hotspotData.fontAwesomeIconName}
                     fontAwesomeIconStyle={hotspotData.fontAwesomeIconStyle}
                     contentType={hotspotData.iconOverride ?? hotspotData.contentType} />
               </motion.button>
               <HotspotLabel
                  hotspotData={hotspotData}
                  clickable={clickable}
                  hotspotActive={active}
                  hotspotOptions={hotspotOptions}
               />
         </motion.div>
      );
   }
   else return (
      <motion.div ref={hotspotRef}
         className={`hotspot-container invisible`}
         data-normal={normal}
         data-position={position}
         slot={`hotspot-${hotspotData.id}`}
         data-visibility-attribute="visible"
         initial={"closed"}
         animate={clickable ? "open" : "closed"}
         variants={hotspotVariants}
      >
         <div
            className={`hotspot-layout ${clickable ? "" : "not-clickable"}`}
         >
            <motion.button
               className="hotspot pulse"
               onClick={clickable ? onHotspotClicked : null}
               whileHover={clickable && {
                  scale: 1.2,
                  transition: { duration: 0.3 },
               }}
               whileTap={clickable && { scale: 0.9 }}
            >
               <ContentIcon
                  fontAwesomeIconName={hotspotData.fontAwesomeIconName}
                  fontAwesomeIconStyle={hotspotData.fontAwesomeIconStyle}
                  contentType={hotspotData.iconOverride ?? hotspotData.contentType} />
            </motion.button>
            <HotspotLabel
               hotspotData={hotspotData}
               clickable={clickable}
               hotspotActive={active}
               hotspotOptions={hotspotOptions}
            />
         </div>
      </motion.div>
   );
}

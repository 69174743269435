import { motion } from "framer-motion";
import { useHotspotModal } from "../../../contexts/useHotspotModal"
import { getPropertyOrDefault } from "../../../helpers/helpers";
import LinkContent from "../LinkContent/LinkContent";
import TextContent from "../TextContent/TextContent";
import "./HotspotModal.scss"


const HotspotModalPopup = () => {
    const {hotspotData, hotspotOptions, hotspotModalVisible, setHotspotModalVisible} = useHotspotModal();
    const hotspotContent = getPropertyOrDefault(hotspotData.content, "");
    const hotspotLabelContent = typeof(hotspotContent) === "string"  ? (hotspotContent).split('\n') : hotspotContent;
    const hotspotHeadingColor = getPropertyOrDefault(hotspotOptions?.hotspotHeadingColor, "#000000");

    const handleBackgroundClicked = () => {
        setHotspotModalVisible(false);
        }


    return (<>
        {hotspotModalVisible && <div className="pop-container">
        <section className="modal-hotspot-bg" onClick={handleBackgroundClicked}>
        <motion.div className="modal-hotspot-content"
            transition={{ type: "spring" }}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}>
            <div className="modal-hotspot">
            {hotspotData.title && <div className="__title-bar"><div className="__title" style={{color:hotspotHeadingColor}}>{hotspotData.title}</div></div>}
            {hotspotData.contentType === "text" && <TextContent content={hotspotLabelContent.map((line, index) => <span key={index}>{line}<br/></span>)}/>}
            {hotspotData.contentType === "link" && <LinkContent content={hotspotLabelContent} linkUrl={hotspotData.linkUrl}/>}
            </div>
        </motion.div>
        </section>
        </div>}
        </>
      );
}
 
export default HotspotModalPopup;
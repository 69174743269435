export function useVariantUrl(product, variant) {
   let baseUrl;
   switch(process.env.REACT_APP_ENV) {
      case "production":
         baseUrl = "https://cdn.enginecloud.co.uk";
         break;
      case "staging":
         baseUrl = "https://cdn.enginecloud.co.uk/staging";
         break;
      case "development":
         baseUrl = "https://cdn.enginecloud.co.uk/dev";
         break;
      default:
         break;
   }   
   const url = `${baseUrl}/models/${product}/${variant}`;

   return url;
}
